import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const scrollToSection = (elementId) => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="d-flex flex-column position-fixed align-items-center p-4 vh-100" style={{width: '250px',  backgroundColor: '#120909'}}>
            {/* Logo */}
            <Link to="/" className="mb-4">
                <img src="/images/logo.gif" alt="Logo" style={{width: '150px'}} />
            </Link>

            {/* Gezinme Menüsü */}
            <nav className="nav flex-column w-100 mt-4 gap-2">
                <button onClick={() => scrollToSection('home')} className="nav-link text-white text-start"><i class="bi bi-house-heart-fill"></i><span className="ms-3">Ana Sayfa</span></button>

                <button onClick={() => scrollToSection('egitim')} className="nav-link text-white text-start"><i class="bi bi-mortarboard-fill"></i><span className="ms-3">Eğitim</span></button>

                <button onClick={() => scrollToSection('is-tecrubesi')} className="nav-link text-white text-start"><i class="bi bi-building"></i><span className="ms-3">İş Tecrübesi</span></button>

                <button onClick={() => scrollToSection('yetenekler')} className="nav-link text-white text-start"><i class="bi bi-code-slash"></i><span className="ms-3">Yetenekler</span></button>

                <button onClick={() => scrollToSection('projeler')} className="nav-link text-white text-start"><i class="bi bi-filetype-js"></i><span className="ms-3">Projeler</span></button>

                <button onClick={() => scrollToSection('iletisim')} className="nav-link text-white text-start"><i class="bi bi-envelope-fill"></i><span className="ms-3">İletişim</span></button>
            </nav>

            <a 
                href="/files/Bünyamin YUSUFOĞLU.pdf" 
                download="Bünyamin YUSUFOĞLU - CV.pdf"
                className="btn btn-outline-light mt-5 w-100 d-flex align-items-center justify-content-center gap-2"
            >
                <i className="bi bi-file-earmark-pdf-fill"></i> 
                Özgeçmiş İndir
            </a>

        </div>
    );
};

export default Header;