import React from 'react';
import Header from '../Header/Header';

const Template = ({ children }) => {
    
    return (
        <div className="d-flex template">

            {/* Sol taraftaki Header */}
            <div className="sidebar" style={{ minWidth: '250px', width: '250px' }}>
                <Header />
            </div>
            
            {/* İçerik alanı */}
            <div className="content flex-grow-1 p-5">
                {children}
            </div>
        </div>
        
    );
};

export default Template;