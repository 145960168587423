import React, { useState, useEffect } from 'react';

const HomePageComponent = () => {
    const [homeData, setHomeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Veriler yüklenirken bir hata oluştu');
                }
                return response.json();
            })
            .then(data => {
                setHomeData(data.home);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="home" className="container d-flex justify-content-center">
            <div className="row mb-5">
                {/* Sol taraftaki resim */}
                <div className="col-md-5">
                    <div className="position-relative">
                        <div className="home-image-frame">
                            <img 
                                src={homeData.image}
                                alt="Profil" 
                                className="home-image"
                            />
                        </div>
                    </div>
                </div>

                {/* Sağ taraftaki yazılar */}
                <div className="col-md-7 d-flex flex-column justify-content-center">
                    <div className="animate-text">
                        <h2 className="display-4 fw-bold mb-0">{homeData.name}</h2>
                        <h3 className="display-6 text-muted mb-4 typ-text">{homeData.title}</h3>
                        <div className="typing-text mb-4">
                            {homeData.skills.map((skill, index) => (
                                <React.Fragment key={index}>
                                    <span className={skill.color}>{skill.text}</span>
                                    {index < homeData.skills.length - 1 && '|'}
                                </React.Fragment>
                            ))}
                        </div>
                        <p className="description-text mb-4">
                            <span>{homeData.description.text1}</span>
                            <span className="highlight">{homeData.description.highlights[0]}</span>
                            <span>{homeData.description.text2}</span>
                            <span className="tech-stack">{homeData.description.techStack}</span>
                            <span>{homeData.description.text3}</span>
                            <span className="highlight">{homeData.description.highlights[1]}</span>
                            <span>{homeData.description.text4}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePageComponent;
