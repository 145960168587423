import React, { useState, useEffect } from 'react';

const EgitimComponent = () => {
    const [education, setEducation] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Eğitim bilgileri yüklenirken bir hata oluştu');
                }
                return response.json();
            })
            .then(data => {
                setEducation(data.education);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="egitim" className="container py-5">
            <h2 className="section-title text-center mb-5">EĞİTİM BİLGİLERİ</h2>
            
            {education.map((edu, index) => (
                <div key={index} className="education-card">
                    <div className="row align-items-center">
                        <div className="col-md-2 text-center">
                            <div className="education-year">
                                {edu.year}
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="education-content">
                                <h3 className="university-name">
                                    <i className="bi bi-buildings-fill me-2"></i>
                                    {edu.university}
                                </h3>
                                <h4 className="department-name">
                                    <i className="bi bi-mortarboard-fill me-2"></i>
                                    {edu.department}
                                </h4>
                                <p className="education-description">
                                    {edu.description}
                                </p>
                                <div className="course-tags">
                                    {edu.courses.map((course, idx) => (
                                        <span key={idx} className="course-tag">
                                            {course}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EgitimComponent;