import React, { useState, useEffect } from 'react';

const IletisimComponent = () => {
    const [contact, setContact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('İletişim bilgileri yüklenirken bir hata oluştu');
                }
                return response.json();
            })
            .then(data => {
                setContact(data.contact);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="iletisim" className="container py-5">
            <h2 className="section-title text-center mb-5">İLETİŞİM</h2>
            
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="contact-card">
                        <div className="contact-info">
                            {contact.info.map((item, index) => (
                                <div key={index} className="info-item">
                                    <i className={`bi ${item.icon} me-3`}></i>
                                    <span>{item.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
                <div className="col-md-6">
                    <div className="contact-card">
                        <div className="contact-info">
                            {contact.social.map((item, index) => (
                                <div key={index} className="info-item">
                                    <i className={`bi ${item.icon} me-3`}></i>
                                    <a 
                                        href={item.link}
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="social-link"
                                    >
                                        {item.text}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IletisimComponent;