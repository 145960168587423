import React, { useState, useEffect } from 'react';

const ProjelerComponent = () => {
    const [projeler, setProjeler] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {

                if (!response.ok) {
                    throw new Error('Projeler yüklenirken bir hata oluştu');
                }
                return response.json();
            })
            .then(data => {
                setProjeler(data.projects);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="projeler" className="container py-5">
            <h2 className="section-title text-center mb-5">PROJELER</h2>
            <div className="row g-4">
                {projeler.map((proje, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-12">
                        <div className="project-card border border-2 border-gray-300">
                            <div className="project-image">
                                <img src={proje.img} alt={proje.adi} className="img-fluid" />
                            </div>
                            <div className="project-content">
                                <h3 className="project-title">{proje.adi}</h3>
                                <p className="project-description">{proje.aciklama}</p>
                                <div className="project-tech">
                                    {proje.diller.map((dil, idx) => (
                                        <span key={idx} className="tech-badge">
                                            {dil}
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="project-links d-flex justify-content-evenly">
                                    <a 
                                        href={proje.link} 
                                        target="_blank"
                                        rel="noopener noreferrer" 
                                        className="project-link w-50 p-3"
                                    >
                                        <i className="bi bi-github me-2"></i>
                                        Github
                                    </a>
                                    <a 
                                        href={proje.link2} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="project-link w-50 p-3"
                                    >
                                        <i className="bi bi-aspect-ratio me-2"></i>
                                        Live
                                    </a>
                                </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProjelerComponent;