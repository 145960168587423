import React from 'react';
import Template from '../components/Template/Template';
import HomePageComponent from '../components/HomePage/HomePageComponent';
import EgitimComponent from '../components/Egitim/EgitimComponent';
import IsTecrubesiComponent from '../components/IsTecrubesi/IsTecrubesiComponent';
import YetenekComponent from '../components/Yetenek/YetenekComponent';
import ProjelerComponent from '../components/Projeler/ProjelerComponent';
import IletisimComponent from '../components/Iletisim/IletisimComponent';

const HomePage = () => {
    const scrollToSection = (elementId) => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <Template>
            <HomePageComponent />
            <EgitimComponent />
            <IsTecrubesiComponent />
            <YetenekComponent />
            <ProjelerComponent />
            <IletisimComponent />
            <div className="mobil-menu d-flex flex-column align-items-center bg-black py-3 w-100">
                <nav className="nav d-flex justify-content-center flex-row gap-1 w-100">
                    <button onClick={() => scrollToSection('home')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-house-heart-fill"></i><span>Ana Sayfa</span></button>

                    <button onClick={() => scrollToSection('egitim')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-mortarboard-fill"></i><span>Eğitim</span></button>

                    <button onClick={() => scrollToSection('is-tecrubesi')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-building"></i><span>Tecrübe</span></button>

                    <button onClick={() => scrollToSection('yetenekler')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-code-slash"></i><span>Yetenek</span></button>

                    <button onClick={() => scrollToSection('projeler')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-filetype-js"></i><span>Projeler</span></button>

                    <button onClick={() => scrollToSection('iletisim')} className="d-flex flex-column align-items-center nav-link2 text-white text-center">
                    <i className="bi bi-envelope-fill"></i><span>İletişim</span></button>
                </nav>
            </div>
        </Template>
    );
};

export default HomePage;
