import React, { useState, useEffect } from 'react';

const YetenekComponent = () => {
    const [skills, setSkills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Yetenekler yüklenirken bir hata oluştu');

                }
                return response.json();
            })
            .then(data => {
                setSkills(data.skills);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="yetenekler" className="container py-5">
            <h2 className="section-title text-center mb-5">YETENEKLER</h2>
            
            <div className="row g-4">
                {skills.map((skill, index) => (
                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="skill-card border border-2">
                            <div className="skill-icon">
                                <i className={`bi ${skill.icon}`}></i>
                            </div>
                            <h3 className="skill-name">{skill.name}</h3>
                            <div className="skill-level">
                                <div className="progress">
                                    <div 
                                        className="progress-bar" 
                                        role="progressbar" 
                                        style={{width: `${skill.level}%`}}
                                        aria-valuenow={skill.level} 
                                        aria-valuemin="0" 
                                        aria-valuemax="100"
                                    >
                                        {skill.level}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default YetenekComponent;