import React, { useState, useEffect } from 'react';

const IsTecrubesiComponent = () => {
    const [experience, setExperience] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/data/data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('İş tecrübesi bilgileri yüklenirken bir hata oluştu');
                }
                return response.json();
            })
            .then(data => {
                setExperience(data.experience);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="container py-5 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Yükleniyor...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container py-5 text-center text-danger">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div id="is-tecrubesi" className="container py-5">
            <h2 className="section-title text-center mb-5">İŞ TECRÜBESİ</h2>
            
            {experience.map((exp, index) => (
                <div key={index} className="experience-card">
                    <div className="row align-items-center">
                        <div className="col-md-2 text-center">
                            <div className="experience-year">{exp.year}</div>
                            <div className="experience-duration">{exp.duration}</div>
                        </div>
                        <div className="col-md-10">
                            <div className="experience-content">
                                <h3 className="company-name">
                                    <i className="bi bi-building me-2"></i>
                                    {exp.company}
                                </h3>
                                <h4 className="position-name">
                                    <i className="bi bi-person-workspace me-2"></i>
                                    {exp.position}
                                </h4>
                                <div className="location">
                                    <i className="bi bi-geo-alt-fill me-2"></i>
                                    {exp.location}
                                </div>
                                <div className="skill-tags mt-3">
                                    {exp.skills.map((skill, idx) => (
                                        <span key={idx} className="skill-tag">
                                            {skill}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default IsTecrubesiComponent;
